<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24">
              <ElFormItem :label="$t('language')">
                <ElSelect
                  v-model="selectedFieldLanguage"
                  :autocomplete="true"
                  :placeholder="$t('select_an_option')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <template #prefix>
                    <img
                      v-if="selectedFieldLanguageFlag"
                      :src="selectedFieldLanguageFlag.flag"
                    />
                  </template>
                  <el-option
                    v-for="item in languages"
                    :key="item.iso_code"
                    :label="item.label"
                    :value="item.iso_code"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24">
              <ElFormItem
                v-if="selectedFieldLanguage"
                :prop="`name_${selectedFieldLanguage.toLowerCase()}`"
                :label="`${$t('name')} *`"
                class="translation"
              >
                <ElInput
                  v-model="form[`name_${selectedFieldLanguage.toLowerCase()}`]"
                  @keydown.enter.prevent="$emit('validateForm')"
                />
                <Icons
                  name="20px/translation"
                  @click="
                    translateField(
                      `name_${selectedFieldLanguage.toLowerCase()}`
                    )
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
              v-if="!guide"
            >
              <ElFormItem
                :label="$t('guide')"
                class="autocomplete-new-entity"
                :class="form.guide_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getGuideKey()"
                  v-loading="getGuideKey() === 0"
                  v-model="form.guide_uuid"
                  :remote-method="searchGuides"
                  :placeholder="$t('select_guide')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchGuides(form.guide)"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewGuideDialog = true"
                />
                <Icons
                  v-if="form.guide_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/guides/guides', {
                      uuid: form.guide_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="location_uuid"
                :label="$t('main_location')"
                class="autocomplete-new-entity"
                :class="form.location_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getLocationKey()"
                  v-loading="getLocationKey() === 0"
                  v-model="form.location_uuid"
                  :remote-method="searchLocations"
                  :placeholder="$t('select_a_location')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchLocations()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewLocationDialog = true"
                />
                <Icons
                  v-if="form.location_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/locations/locations', {
                      uuid: form.location_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewGuideDialog"
      :width="width <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_guide') }}
        </span>
      </template>
      <QuickCreateGuideDialog
        @create=";(showNewGuideDialog = false), createGuide($event)"
        @cancel="showNewGuideDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewLocationDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_location') }}
        </span>
      </template>
      <QuickCreateLocationDialog
        @create=";(showNewLocationDialog = false), createLocation($event)"
        @cancel="showNewLocationDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_TOUR from '~/graphql/tours/mutation/createTourNoAddress.gql'

const props = defineProps({
  guide: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  name_es: '',
  name_en: '',
  name_fr: '',
  name_de: '',
  name_sv: '',
  name_ar: '',
  guide_uuid: props.guide?.uuid ?? '',
  customer_price: 0,
  paradise_price: 0,
  commission: 0,
  prepayment: false,
  payment_by_customer: false,
  currency: 'eur'
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()
const languages = getLanguages()
const selectedFieldLanguage = ref('EN')
const {
  getTargetLang,
  getSourceLanguage,
  fieldsByLang,
  formattedAddressInvalid,
  countriesArray,
  provinces
} = getFormInitialVariables()

const selectedFieldLanguageFlag = computed(() => {
  return languages.find((item) => item.iso_code === selectedFieldLanguage.value)
})

const ruleFormRef = ref()
const loading = ref(false)
const showNewGuideDialog = ref(false)
const showNewLocationDialog = ref(false)

onMounted(() => {
  initialGuides()
  initialLocations()
  setFieldLanguage()
})

const inputRules = reactive({
  name_es: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'ES' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_en: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'EN' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_fr: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'FR' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_de: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'DE' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_sv: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'SV' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_ar: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'AR' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  guide_uuid: [
    {
      required: true,
      message: t('guide_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  location_uuid: [
    {
      required: true,
      message: t('location_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const rules = {
  name_es: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'ES'
    })
  },
  name_en: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'EN'
    })
  },
  name_fr: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'FR'
    })
  },
  name_de: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'DE'
    })
  },
  name_sv: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'SV'
    })
  },
  name_ar: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'AR'
    })
  },
  guide_uuid: { required },
  location_uuid: { required }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    //
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_TOUR, {
    name_en: form.name_en,
    name_es: form.name_es,
    name_fr: form.name_fr,
    name_de: form.name_de,
    name_sv: form.name_sv,
    name_ar: form.name_ar,
    guide_uuid: form.guide_uuid,
    location_uuid: form.location_uuid,
    customer_price: form.customer_price
      ? parseInt(form.customer_price * 100)
      : 0,
    paradise_price: form.paradise_price
      ? parseInt(form.paradise_price * 100)
      : 0,
    commission: form.commission ? parseInt(form.commission * 100) : 0,
    prepayment: form.prepayment,
    payment_by_customer: form.payment_by_customer,
    currency: form.currency
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createTour ?? null)
    loading.value = false
  }
}

const setFieldLanguage = () => {
  if (getAuthUser()?.value?.language?.toLowerCase() === 'en') {
    selectedFieldLanguage.value = 'EN'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'es') {
    selectedFieldLanguage.value = 'ES'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'fr') {
    selectedFieldLanguage.value = 'FR'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'de') {
    selectedFieldLanguage.value = 'DE'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'sv') {
    selectedFieldLanguage.value = 'SV'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'ar') {
    selectedFieldLanguage.value = 'AR'
  }
}

const translateField = async (field) => {
  try {
    loading.value = true
    const response = await mutationTranslation(
      {
        text: form[field],
        source_lang: getSourceLanguage(field).name,
        target_lang: getTargetLang(field)
      },
      t,
      getSourceLanguage(field).name
    )
    const fieldByLang = fieldsByLang(field)
    if (response?.length) {
      response.forEach((item) => {
        const fieldName = fieldByLang[item.target_lang]
        if (fieldName) {
          form[fieldName] = item.translated_text
        }
      })
    }
  } catch (err) {
    $showError(err, t)
    $sentry(err, 'translateField')
  } finally {
    loading.value = false
  }
}

const createGuide = (event) => {
  if (event?.uuid) {
    form.guide_uuid = event.uuid
    form.guide = event
    searchGuides(event.first_name, form.guide, null, true)
  }
}

const createLocation = (event) => {
  if (event?.uuid) {
    form.location_uuid = event.uuid
    form.location = event
    searchLocations(event.name, form.location, null, true)
  }
}
</script>

<style>
</style>